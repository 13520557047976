import { graphql } from 'gatsby';

import indexPageWrapper from '../components/IndexPage';

const indexPage = indexPageWrapper('countries-and-basins');

export default indexPage;

/* eslint-disable no-undef */
export const pageQuery = graphql`
  query CountriesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {path: {regex: "/^\/countries-and-basins/i" }}}) {
      edges {
        node {
          id
          html
          frontmatter {
            path
            title
            menuOrder
          }
        }
      }
    }
  }
`;
